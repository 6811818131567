$dark: #212121;
$light: #efefef;
$color: #b3e5fc;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
    max-width: 100vw;
    overflow-x: hidden;
}

.check {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

#alert {
    transition: 0.7s all ease-in-out;
}
.alert {
    width: fit-content;
    height: 48px;
    border: 1px solid #E08983;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.23);
    border-radius: 26px;

    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;

    color: #F5F6F6;

    animation: 0.4s alert-animation ease-in-out ;
    transition: 0.3s all ease-in-out;
    border: none !important;
}

@keyframes alert-animation {
    0%,40%{
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;

    }
    
}
#nav-icon2 {
    width: 25px;
    height: 45px;
    position: absolute;
    margin: auto;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    top: 18px;
    right: 30px;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 50%;
        background: #000000;
        opacity: 1;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }

    span:nth-child(even) {
        left: 50%;
        border-radius: 0 9px 9px 0;
    }

    span:nth-child(odd) {
        left: 0px;
        border-radius: 9px 0 0 9px;
    }

    span:nth-child(1),
    span:nth-child(2) {
        top: 0px;
    }

    span:nth-child(3),
    span:nth-child(4) {
        top: 8px;
    }

    span:nth-child(5),
    span:nth-child(6) {
        top: 16px;
    }
}


#nav-icon2.open span:nth-child(1),
#nav-icon2.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),
#nav-icon2.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
    left: 5px;
    top: 7px;
}

#nav-icon2.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 7px;
}

#nav-icon2.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
    left: 5px;
    top: 9px;
}

#nav-icon2.open span:nth-child(6) {
    left: calc(50% - 5px);
    top: 9px;
}

.navbar-mobile {
    height: calc(100vh - 51px);
    margin-top: 51px;

    >div {
        height: 100%;
        position: relative;

        div:nth-child(1) {
            ul {
                padding-top: 30px;
            }

            ul li {
                margin-bottom: 24px;
            }
        }

        .action {
            position: absolute;
            height: fit-content;
            margin: auto;
            bottom: 5%;
            width: 335px;

            .btn {
                width: 100%;
                margin-bottom: 16px;
                text-transform: capitalize;
            }

            .btn.login {
                border: 1px solid #5557DB;
                color: #5557DB;
                border-radius: 4px;
                background-color: #FFFFFF;
            }

            .btn.register {
                color: #FFFFFF;
                border-radius: 4px;
            }
        }
    }

    .submenu {
        padding: 24px;
        position: absolute;
        top: 103px;
        width: 335px;
        height: 313px;
        background: #FFFFFF;
        border: 1px solid #F5F6F6;
        box-shadow: 0px 12px 12px rgba(50, 50, 71, 0.08), 0px 16px 24px rgba(50, 50, 71, 0.08);

        .img-product {
            width: 54.53px;
            height: 56px;
            background: #FFFFFF;
            box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .title-product {
            margin-left: 4px;
            width: 186px;

            h4 {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #0F1628;
            }

            p {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.006em;
                color: #4B505E;

            }
        }

        #arrow {
            transition: 0.5s all ease-in-out;
        }
    }
}

.navbar-full {
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.05);
}

.navbar {

    .title,
    .title-sub {
        font-family: 'OutfitMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-left: 30px;
        margin-right: 30px;
        padding-left: 0px;
        padding-right: 0px;
        color: #5E5D5C;
        width: auto;
    }

    .menu :where(li)> :where(*:not(ul)) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .menu> :where(li) :where(ul) {
        flex-direction: inherit;
    }

    ul .title-sub:hover svg {
        transform: rotate(180deg);
        transition: 0.3s ease-in-out;
    }

    .btn-login {
        background: #FFFFFF;
        border: 1px solid #5557DB;
        border-radius: 3.5px;
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 12px;
        display: flex;
        align-items: center;
        color: #5557DB;
        padding: 12px 32px 10px 31px;
    }

    .btn-register {
        background: #5557DB;
        border-radius: 3.5px;
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 12px;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        padding: 12px 32px 10px 31px;
    }

    .mega-menu {
        background: #FFFFFF;
        border: 1px solid #F5F6F6;
        box-shadow: 0px 12px 12px rgb(50 50 71 / 8%), 0px 16px 24px rgb(50 50 71 / 8%);
        border-radius: 12px;
        width: 785px;

        .kyoo {
            width: 56px;
            height: 56px;
            background: url('../assets/images/logo-svg/kyo.svg') no-repeat, #2F5CFD;
            background-position: center;
            box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
            border-radius: 6px;
        }

        .web-toko {
            width: 56px;
            height: 56px;
            background: url('../assets/images/logo-svg/web-toko.svg') no-repeat, #2F5CFD;
            background-position: center;
            box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
            border-radius: 6px;
        }

        .sikas {
            width: 56px;
            height: 56px;
            background: url('../assets/images/logo-svg/sikas.svg') no-repeat, #7373BC;
            background-position: center;
            box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
            border-radius: 6px;
        }

        .siresto {
            width: 56px;
            height: 56px;
            background: url('../assets/images/logo-svg/siresto.svg') no-repeat, #7373BC;
            background-position: center;
            box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
            border-radius: 6px;
        }


        .siqasir {
            width: 56px;
            height: 56px;
            background: url('../assets/images/logo-svg/siqasir.svg') no-repeat, #FDA92F;
            background-position: center;
            box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
            border-radius: 6px;
        }


        .tautan {
            width: 56px;
            height: 56px;
            background: url('../assets/images/logo-svg/tautan.svg') no-repeat, #FDA92F;
            background-position: center;
            box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
            border-radius: 6px;
        }


        h3 {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #0F1628;
        }

        h4 {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            max-width: 285px;
            line-height: 20px;
            letter-spacing: -0.006em;
            color: #A4A6AD;
        }
    }

    .mega-menu li:hover h3 {
        background: linear-gradient(115.46deg, #E68800 11.79%, rgba(86, 6, 255, 0) 163.67%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .mega-menu li:hover h4 {
        color: #4B505E;
    }

    // .menu {
    //     transition: 0s;
    // }

    .menu[class*=" p-"] li>*,
    .menu[class^="p-"] li>* {
        border-radius: 0px;
    }

    .open {
        margin-left: 0px;
    }

}

@media (max-width: 767px) {
    .section1 {
        ol.words {
            display: inline-block;
            margin: 0;
            padding: 0;
            padding-bottom: 5px;
            vertical-align: top;
            height: 1.3em;
            overflow: hidden;
            width: 395px;
            text-align: center;
        }

        ol.words>li {
            list-style: none;
            animation-name: change;
            animation-duration: 5.6s;
            animation-direction: alternate;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 50px;
            line-height: 63px;
            border-radius: 12px;
        }

        ol.words>li:nth-child(1) {
            margin: 2% 0% 4% 0%;
            width: fit-content;
            padding: 0% 3%;
            background-color: #FDA92F;
            color: #fff;

        }

        ol.words>li:nth-child(2) {
            margin: 2% 0% 4% 0%;
            padding: 0% 3%;
            width: fit-content;
            background: #5557DB;
            color: #fff;

        }

        ol.words>li:nth-child(3) {
            margin: 2% 0% 4% 0%;
            padding: 0% 3%;
            width: fit-content;
            background-color: #dce021;
            color: #fff;
        }

        ol.words>li:nth-child(4) {
            width: fit-content;
            margin: 2% 0%;
            padding: 0% 3%;
            background-color: #FDA92F;
            color: #fff;
        }

        @keyframes change {
            0% {
                transform: translate3d(0, 0%, 0);
            }

            10.8333333333% {
                transform: translate3d(0, 0%, 0);
            }

            19.1666666667% {
                transform: translate3d(0, -100%, 0);
            }

            50.8333333333% {
                transform: translate3d(0, -100%, 0);
            }

            59.1666666667% {
                transform: translate3d(0, -220%, 0);
            }

            80.8333333333% {
                transform: translate3d(0, -220%, 0);
            }

            89.1666666667% {
                transform: translate3d(0, -330%, 0);
            }

            100% {
                transform: translate3d(0, -330%, 0);
            }
        }

        @media (max-width: 767px) {
            ol.words>li:nth-child(1) {
                margin: 0;

                width: fit-content;
                padding: 0px 12px;

                background-color: #763fec;
                color: #fff;

                line-height: 63px;
            }

            ol.words>li:nth-child(2) {
                margin: 2px 0px 10px 0px;

                width: fit-content;
                background-color: #f25151;
                color: #fff;

                line-height: 63px;
            }

            ol.words>li:nth-child(3) {
                margin: 10px 0px;

                width: fit-content;
                background-color: #dce021;
                color: #fff;

                line-height: 63px;
            }

            ol.words>li:nth-child(4) {
                width: fit-content;
                margin: 10px 0px;

                background-color: #1cc00d;
                color: #fff;

                line-height: 63px;
            }
        }

        h2 {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 50px;
            line-height: 63px;

            color: #505050;
        }

        p {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            font-feature-settings: 'liga' off;
            color: #4B505E;
            width: 382px;
            margin-bottom: 48px;
        }

        .btn.lihat-produk {
            width: 180px;
            height: 60px;
            left: 106px;
            top: 594px;

            background: #5557DB;
            box-shadow: 0px 24px 30px -16px rgba(16, 56, 206, 0.25);
            border-radius: 8px;

            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;

            display: flex;
            align-items: center;

            color: #FFFFFF;
        }

    }
}

@media (min-width: 768px) {
    .section1 {
        ol.words {
            display: inline-block;
            margin: 0;
            padding: 0;
            padding-bottom: 5px;
            vertical-align: top;
            height: 1.3em;
            overflow: hidden;
            width: 395px;
            text-align: center;
        }

        ol.words>li {
            list-style: none;
            animation-name: change;
            animation-duration: 5.6s;
            animation-direction: alternate;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 62px;
            line-height: 78px;
            border-radius: 12px;
        }

        ol.words>li:nth-child(1) {
            margin: 2% 0% 4% 0%;
            width: fit-content;
            padding: 0% 3%;
            background-color: #FDA92F;
            color: #fff;

        }

        ol.words>li:nth-child(2) {
            margin: 2% 0% 4% 0%;
            padding: 0% 3%;
            width: fit-content;
            background: #5557DB;
            color: #fff;

        }

        ol.words>li:nth-child(3) {
            margin: 2% 0% 4% 0%;
            padding: 0% 3%;
            width: fit-content;
            background-color: #dce021;
            color: #fff;
        }

        ol.words>li:nth-child(4) {
            width: fit-content;
            margin: 2% 0%;
            padding: 0% 3%;
            background-color: #FDA92F;
            color: #fff;
        }

        @keyframes change {
            0% {
                transform: translate3d(0, -10%, 0);
            }

            10.8333333333% {
                transform: translate3d(0, -10%, 0);
            }

            19.1666666667% {
                transform: translate3d(0, -130%, 0);
            }

            50.8333333333% {
                transform: translate3d(0, -130%, 0);
            }

            59.1666666667% {
                transform: translate3d(0, -250%, 0);
            }

            80.8333333333% {
                transform: translate3d(0, -250%, 0);
            }

            89.1666666667% {
                transform: translate3d(0, -370%, 0);
            }

            100% {
                transform: translate3d(0, -370%, 0);
            }
        }

        @media (max-width: 767px) {
            ol.words>li:nth-child(1) {
                margin: 0;

                width: fit-content;
                padding: 2px 12px;

                background-color: #763fec;
                color: #fff;

                line-height: 68px;
            }

            ol.words>li:nth-child(2) {
                margin: 0px 0px 10px 0px;

                width: fit-content;
                background-color: #f25151;
                color: #fff;

                line-height: 68px;
            }

            ol.words>li:nth-child(3) {
                margin: 10px 0px;

                width: fit-content;
                background-color: #dce021;
                color: #fff;

                line-height: 68px;
            }

            ol.words>li:nth-child(4) {
                width: fit-content;
                margin: 10px 0px;

                background-color: #1cc00d;
                color: #fff;

                line-height: 68px;
            }
        }

        h2 {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 62px;
            line-height: 78px;

            color: #505050;
        }

        p {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            font-feature-settings: 'liga' off;
            color: #4B505E;
            width: 382px;
            margin-bottom: 48px;
        }

        .btn.lihat-produk {
            width: 180px;
            height: 60px;
            left: 106px;
            top: 594px;

            background: #5557DB;
            box-shadow: 0px 24px 30px -16px rgba(16, 56, 206, 0.25);
            border-radius: 8px;

            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;

            display: flex;
            align-items: center;

            color: #FFFFFF;
        }

    }
}

@media (max-width: 767px) {
    .section2 {
        background: url("../assets/images-mobile/section2/background.png");
        padding: 32px 20px 90px 20px;
        border-radius: 40px;
        background-size: 100%;
        height: fit-content;

        h3 {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            max-width: 290px;
            color: #FFFFFF;
            margin-bottom: 24px;
        }

        p {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.019em;
            color: #EEEFF0;
            margin-bottom: 20px;
        }

        .content {
            .card.medium.sikas {
                background-color: #FFFFFF;
                height: 432px;
                padding: 26px 19px 24px 17px;
                margin-bottom: 24px;

                img:first-child {
                    width: 73.75px;
                }

                img:nth-child(2) {
                    width: 70%;
                    margin: 26px auto;
                }

                p {
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: -0.017em;
                    color: #4B505E;
                }

                .btn,
                .btn:focus,
                .btn:hover {
                    width: 214px;
                    height: 46px;
                    background: #5557DB;
                    border-radius: 6px;
                    margin: auto;
                    text-transform: capitalize;
                    color: #FFFFFF;
                    margin-bottom: 24px;
                }
            }

            .card.medium.kyoo {
                background-color: #FFFFFF;
                height: 432px;
                padding: 26px 19px 24px 17px;
                margin-bottom: 24px;

                img:first-child {
                    width: 73.75px;
                }

                img:nth-child(2) {
                    width: 70%;
                    margin: 26px auto;
                }

                p {
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: -0.017em;
                    color: #4B505E;
                }

                .btn,
                .btn:focus,
                .btn:hover {
                    width: 214px;
                    height: 46px;
                    background: #5557DB;
                    border-radius: 6px;
                    margin: auto;
                    text-transform: capitalize;
                    color: #FFFFFF;
                    margin-bottom: 24px;
                }
            }

            .card.long.kyoo,
            .card.long.sikas {
                height: 635px;
                background-color: #FFFFFF;
                padding: 26px 19px 24px 17px;


                img:first-child {
                    width: 73.75px;
                }

                img:nth-child(2) {
                    width: calc(100% - 32px);
                    margin: 26px auto;
                }

                .btn {
                    width: 214px;
                    height: 46px;
                    background: #5557DB;
                    border-radius: 6px;
                    text-transform: capitalize;
                    color: #FFFFFF;
                    margin-top: 16px;
                    margin-bottom: 24px;
                }
            }
        }

        ul li {
            display: flex;
            margin-bottom: 8px;
        }

        .check {
            margin-top: 6px;
            width: 15.6px !important;
            height: 15.6px;
        }

    }
}

@media (min-width: 768px) {
    .section2 {
        background: url("../assets/images/section2/background.jpg") no-repeat;
        background-size: 100% 100%;
        height: fit-content;
        padding-bottom: 10%;

        .top-section {
            position: relative;

            svg:nth-child(1) {
                position: absolute;
                margin: -69px auto 0 auto;
                left: 0;
                right: 0;
            }

            img {
                position: absolute;
                transform: rotate(354deg);
                right: 5px;
                top: -92px;
            }
        }

        .content {
            // max-width: 1000px;

            .title.left {
                display: flex;
                align-items: center;

                h2 {
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 48px;
                    line-height: 49px;
                    display: flex;
                    align-items: center;
                    color: #EEEFF0;
                }
            }

            .title.right {
                display: flex;
                justify-content: flex-end;

                p {
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    letter-spacing: -0.019em;
                    color: #EEEFF0;
                    margin-right: 0;
                    max-width: 434px;

                }
            }

            .btn-group {
                column-gap: 18px;

                input {
                    height: 57.24px;
                    width: fit-content;
                    border-radius: 166.21px !important;
                    border: none !important;
                    outline: none !important;
                    padding: 16.62px 22.16px;
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: -0.011em;
                    text-transform: capitalize;
                    background-color: #FFFFFF;
                }

                input[type="radio"].btn:before {
                    background: linear-gradient(115.46deg, #E68800 11.79%, rgba(86, 6, 255, 0) 163.67%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                input[type="radio"]:checked {
                    background: #5557DB;
                    color: #FFFFFF;
                }

                input[type="radio"]:checked.btn:before {
                    color: #FFFFFF;
                    background: #FFFFFF;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .card {
                background: rgba(255, 255, 255, 0.95);
                box-shadow: 0px 24px 32px rgba(50, 50, 71, 0.08);
                border-radius: 60px;
                width: full;
                height: auto;
                height: 636px;

                .sikas {
                    width: 130px;
                }

                .kyoo {
                    width: 116px;
                }

                p {
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 28px;
                    letter-spacing: -0.017em;
                    color: #7D818B;
                    margin-top: 31.6px;
                }

            }

            .medium {
                padding: 37px 35px;
            }

            .medium.sikas {
                background: url('../assets/images/section2/background-sikas.png') no-repeat, #FFFFFF;
                background-position: 0% 0%;
                background-size: 40% 60%;
                position: relative;

                img:nth-child(2) {
                    width: 70%;
                    height: auto;
                    position: absolute;
                    left: 21%;
                    top: 14%;
                }
            }

            .medium.kyoo {
                position: relative;

                img:nth-child(2) {
                    width: 100%;
                    position: absolute;
                    right: 0;
                    top: 13%;
                }
            }

            .big {
                padding: 60px 60px 0px 60px;
            }

            .big.kyoo {
                >div:nth-child(2) {
                    position: relative;

                    img {
                        width: 90%;
                        position: absolute;
                        height: auto;
                        bottom: 0;
                        right: 0;
                    }
                }
            }
        }

        ul {
            margin-top: 37px;
            margin-bottom: 48px;
        }

        li {
            margin-bottom: 16px;
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.017em;
            color: #4B505E;
            display: flex;
            column-gap: 4px;
        }

        .btn,
        .btn:hover,
        .btn:focus {
            background: #5557DB;
            color: #ffffff;
            text-transform: capitalize;
            width: 214px;
            height: 60px;
            border-radius: 6px;
        }
    }
}

@media (max-width: 767px) {
    .section3 {
        height: 737px !important;
        background: url("../assets/images-mobile/section3/cloud.png") no-repeat, #FDA92F;
        background-position: 0% 96%;
        border-radius: 40px;
        padding: 32px 20px;
        margin-top: -60px;

        >div:nth-child(1) {
            h2 {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 30px;
                max-width: 300px;
                color: #FFFFFF;
            }

            margin-bottom: 57px;
        }

        >div:nth-child(2) {

            h4 {
                max-width: 250px;
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: -0.017em;
                color: #FFFFFF;
            }
        }
    }
}

@media (min-width: 768px) {
    .section3 {
        margin-top: -50px;
        height: 889px;
        background: url("../assets/images/section3/cloud.png") no-repeat, url("../assets/images/section3/cloud-top.png") no-repeat, #FDA92F;
        background-position: 0% 88%, 80% 13%;
        border-radius: 60px;
        padding-top: 10%;
        margin-bottom: 10%;

        .title {
            margin: 0px auto 78px auto;

            .left {
                max-width: 470px;

                h4 {
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.011em;
                    color: #F5F6F6;
                    margin-bottom: 17px;
                }

                h2 {
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 48px;
                    line-height: 49px;
                    display: flex;
                    align-items: center;
                    color: #FFFFFF;
                }
            }
        }

        .content {
            margin: auto;

            h4 {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: -0.017em;
                color: #FFFFFF;

            }
        }

    }
}

@media (max-width: 767px) {
    .section4 {
        background: #5557DB;
        width: 100%;
        height: 698px;
        border-radius: 40px;
        padding: 32px 20px;
        margin-bottom: 36px;
        margin-top: -58px;

        h2 {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;

            color: #FFFFFF;
        }

        p {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.019em;
            color: #EEEFF0;
            margin-bottom: 24px;
        }
    }
}

@media (min-width: 768px) {
    .section4 {
        margin-top: -18%;
        height: 864px;
        background: #5557DB;
        border-radius: 60px;
        padding-top: 4%;

        >div {
            margin: 0px auto -69px auto;
        }

        h2 {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 48px;
            line-height: 49px;
            margin-bottom: 38px;
            color: #FFFFFF;
            word-break: break-word;

        }

        p {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            align-items: center;
            letter-spacing: -0.017em;
            color: #FFFFFF;
            word-break: break-word;

        }
    }
}

@media (max-width: 767px) {
    .section5 {
        h2 {
            font-family: 'OutfitSemiBold';
            font-style: normal;
            font-weight: 600;
            font-size: 46px;
            line-height: 58px;
            color: #505050;

        }

        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            color: #525D73;
        }


        .btn,
        .btn:hover,
        .btn:focus {
            text-transform: capitalize;
            display: flex;
            margin: 0;
            column-gap: 8px;
            width: 169px;
            height: 61px;
            left: 111px;
            bottom: 581px;
            background: #FDA92F;
            box-shadow: 0px 16px 30px rgb(253 169 47 / 31%);
            border-radius: 8px;
            letter-spacing: -0.006em;
            color: #FFFFFF;
            font-family: "Outfit";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            padding-left: 10px;
            line-height: 20px;
            padding-right: 12px;
        }
    }
}

@media (min-width: 768px) {
    .section5 {
        background: url("../assets/images/section5/banner.png") no-repeat;
        background-position: 100% 0%;
        background-size: 50% auto;

        >div {
            margin: auto;
            padding-top: 107px;

        }

        h2 {
            width: 545px;
            height: 190px;

            font-family: 'Outfit';
            font-style: normal;
            font-weight: 700;
            font-size: 72px;
            line-height: 95px;
        }

        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            width: 504px;
            color: #525D73;
        }

        .btn,
        .btn:hover,
        .btn:focus {
            display: flex;
            margin: 0;
            column-gap: 4px;
            width: 169px;
            height: 61px;
            left: 111px;
            bottom: 581px;
            background: #FDA92F;
            box-shadow: 0px 16px 30px rgb(253 169 47 / 31%);
            border-radius: 8px;
            letter-spacing: -0.006em;
            color: #FFFFFF;
            font-family: "Outfit";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            padding-left: 10px;
            line-height: 20px;
            padding-right: 12px;
        }
    }
}

.footer {
    // background: #313C5F;
    background: url('../assets/images/footer/footer.jpg') no-repeat;
    background-position: 0% 0%;
    background-size: 100% 100%;
    height: 765px;

    .mobile {
        padding: 20px 20px 0px 20px;
        width: 100% !important;

        h3 {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.017em;
            color: #F5F6F6;
            margin-bottom: 14px;
            margin-top: 32px;

        }

        h3:nth-child(1) {
            margin-top: 0;
        }

        ul.menu {
            list-style: none;

            li {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.011em;
                color: #C5C7CB;
                margin-bottom: 11px;
            }
        }



        >img {
            margin-top: 34px;
            margin-bottom: 90px;
        }

        p.support {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.011em;
            color: #F5F6F6;
        }

        .social-media {
            margin-top: 24px;

            ul {
                margin-bottom: 20px;
            }
        }

        p.copy-right {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.006em;
            color: #909AB4;
            margin-bottom: 20px;
        }
    }
}

@media(min-width: 768px) {
    .footer {
        // background: #313C5F;
        background: url('../assets/images/footer/footer.jpg') no-repeat;
        background-position: 0% 0%;
        background-size: 100% 100%;
        height: 400px;

        .awan-digital {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: -0.006em;
            color: #EEEFF0;
        }

        >div {
            >div:nth-child(1) {
                border-bottom: 1px solid #535E80;
                padding-bottom: 28px;

                .left {
                    .text {
                        margin-top: 27px;
                        margin-bottom: 17px;
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.011em;
                        color: #E2E3E5;
                    }

                    .email {
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.011em;
                        color: #F5F6F6;
                    }
                }

                .right {
                    .title {
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 28px;
                        letter-spacing: -0.017em;
                        color: #F5F6F6;
                        margin-bottom: 12px;
                    }

                    ul li {
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.011em;
                        color: #F5F6F6;
                        margin-bottom: 11px;
                    }
                }
            }

            >div:nth-child(2) {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.006em;
                color: #909AB4;
                margin-top: 28px;
            }
        }
    }
}

.social {
    margin-top: 16px;
}

.social li {
    height: 45px;
    width: 45px;
    background-color: #FFFFFF;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;

    path {
        fill: #2e3d62;
    }
}

.social li:hover {
    background-color: #F4B335;

    path {
        fill: #fff;
    }
}


.slider {
    height: 220px;
}

.slider .left h3 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.019em;
    color: #FFFFFF;
    margin-bottom: 12px;
}

.slider .left p {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #EEEFF0;
}

.slider .slick-list {
    height: 158px;
}

.slider .slick-dots {
    display: flex !important;
    justify-content: flex-start;
    bottom: -59px;
}

.slider .slick-dots li {
    margin: 0;
}

.slider .slick-dots li.slick-active button:before {
    color: #FDA92F;
    opacity: 1;
}

.slider .slick-dots li button:before {
    font-size: 12px;
}

.tncMenu {
    ul {
        padding: 12px;
    }

    li {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;

        font-family: 'OutfitSemiBold';
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;
        letter-spacing: -0.011em;
    }

    li:first-child {
        margin-bottom: 10px;
    }
}